var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: { passedActions: _vm.titleBarActions, title: _vm.title },
      }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "v-expansion-panels",
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("div", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                "You are editing " +
                                  _vm._s(_vm.templateIds.length) +
                                  " templates, expand to review them."
                              ),
                            ]),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "overflow-y-auto",
                                  attrs: { justify: "center" },
                                },
                                _vm._l(_vm.filteredTemplates, function (item) {
                                  return _c("template-card-wrapper", {
                                    key: item.templateID,
                                    staticClass: "d-flex flex-column",
                                    attrs: {
                                      id: item.templateID,
                                      "waypoint-id": _vm.waypointId,
                                      selected: false,
                                      "show-actions": false,
                                      elevation: "5",
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", _vm._b({}, "v-col", _vm.leftColumnAttrs, false), [
                _vm._v("Update"),
              ]),
              _c("v-col", _vm._b({}, "v-col", _vm.rightColumnAttrs, false), [
                _vm._v("New Field value"),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.leftColumnAttrs, false),
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.form.isEnabled.included,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.isEnabled, "included", $$v)
                      },
                      expression: "form.isEnabled.included",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.rightColumnAttrs, false),
                [
                  _c("v-checkbox", {
                    attrs: {
                      disabled: !_vm.form.isEnabled.included,
                      label: "Is Enabled",
                    },
                    model: {
                      value: _vm.form.isEnabled.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.isEnabled, "value", $$v)
                      },
                      expression: "form.isEnabled.value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.leftColumnAttrs, false),
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.form.headerLogoHtml.included,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.headerLogoHtml, "included", $$v)
                      },
                      expression: "form.headerLogoHtml.included",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.rightColumnAttrs, false),
                [
                  _c("v-textarea", {
                    attrs: {
                      label: "Header logo HTML",
                      disabled: !_vm.form.headerLogoHtml.included,
                      rows: "1",
                    },
                    model: {
                      value: _vm.form.headerLogoHtml.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.headerLogoHtml, "value", $$v)
                      },
                      expression: "form.headerLogoHtml.value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.leftColumnAttrs, false),
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.form.thankYouMessageHtml.included,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.thankYouMessageHtml, "included", $$v)
                      },
                      expression: "form.thankYouMessageHtml.included",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.rightColumnAttrs, false),
                [
                  _c("v-textarea", {
                    attrs: {
                      label: "Thank you message HTML",
                      disabled: !_vm.form.thankYouMessageHtml.included,
                      rows: "1",
                    },
                    model: {
                      value: _vm.form.thankYouMessageHtml.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.thankYouMessageHtml, "value", $$v)
                      },
                      expression: "form.thankYouMessageHtml.value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.leftColumnAttrs, false),
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.form.templateDescription.included,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.templateDescription, "included", $$v)
                      },
                      expression: "form.templateDescription.included",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.rightColumnAttrs, false),
                [
                  _c("v-textarea", {
                    attrs: {
                      label: "Template description",
                      disabled: !_vm.form.templateDescription.included,
                      rows: "1",
                    },
                    model: {
                      value: _vm.form.templateDescription.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.templateDescription, "value", $$v)
                      },
                      expression: "form.templateDescription.value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.leftColumnAttrs, false),
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.form.emailSubjectTemplate.included,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.emailSubjectTemplate, "included", $$v)
                      },
                      expression: "form.emailSubjectTemplate.included",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.rightColumnAttrs, false),
                [
                  _c("v-textarea", {
                    attrs: {
                      label: "Email subject template",
                      disabled: !_vm.form.emailSubjectTemplate.included,
                      rows: "1",
                    },
                    model: {
                      value: _vm.form.emailSubjectTemplate.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.emailSubjectTemplate, "value", $$v)
                      },
                      expression: "form.emailSubjectTemplate.value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.leftColumnAttrs, false),
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.form.showGreenBox.included,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.showGreenBox, "included", $$v)
                      },
                      expression: "form.showGreenBox.included",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.rightColumnAttrs, false),
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: "Show Green Box",
                      disabled: !_vm.form.showGreenBox.included,
                    },
                    model: {
                      value: _vm.form.showGreenBox.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.showGreenBox, "value", $$v)
                      },
                      expression: "form.showGreenBox.value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.leftColumnAttrs, false),
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.form.tags.included,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.tags, "included", $$v)
                      },
                      expression: "form.tags.included",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.rightColumnAttrs, false),
                [
                  _c("v-textarea", {
                    attrs: {
                      label: "Tags",
                      disabled: !_vm.form.tags.included,
                      rows: "1",
                    },
                    model: {
                      value: _vm.form.tags.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.tags, "value", $$v)
                      },
                      expression: "form.tags.value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.leftColumnAttrs, false),
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.form.templateFile.included,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.templateFile, "included", $$v)
                      },
                      expression: "form.templateFile.included",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                _vm._b({}, "v-col", _vm.rightColumnAttrs, false),
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Template file",
                      disabled: !_vm.form.templateFile.included,
                    },
                    model: {
                      value: _vm.form.templateFile.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.templateFile, "value", $$v)
                      },
                      expression: "form.templateFile.value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }