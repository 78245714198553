<template>
    <div>
        <ContextTitle :passedActions="titleBarActions" :title="title" />
        <v-container fluid>
            <v-row no-gutters>
                <v-col md="12">
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div class="font-weight-bold">You are editing {{templateIds.length}} templates, expand to review them.</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row justify="center" class="overflow-y-auto">
                                    <template-card-wrapper
                                        v-for="item in filteredTemplates"
                                        :key="item.templateID"
                                        :id="item.templateID"
                                        :waypoint-id="waypointId"
                                        :selected="false"
                                        :show-actions="false"
                                        class="d-flex flex-column"
                                        elevation="5"
                                    />
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-bind="leftColumnAttrs">Update</v-col>
                <v-col v-bind="rightColumnAttrs">New Field value</v-col>
            </v-row>
            <v-row>
                <v-col v-bind="leftColumnAttrs">
                    <v-checkbox v-model="form.isEnabled.included"></v-checkbox>
                </v-col>
                <v-col v-bind="rightColumnAttrs">
                    <v-checkbox v-model="form.isEnabled.value" :disabled="!form.isEnabled.included" label="Is Enabled"></v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-bind="leftColumnAttrs">
                    <v-checkbox v-model="form.headerLogoHtml.included"></v-checkbox>
                </v-col>
                <v-col v-bind="rightColumnAttrs">
                    <v-textarea label="Header logo HTML" v-model="form.headerLogoHtml.value" :disabled="!form.headerLogoHtml.included" rows="1"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-bind="leftColumnAttrs">
                    <v-checkbox v-model="form.thankYouMessageHtml.included"></v-checkbox>
                </v-col>
                <v-col v-bind="rightColumnAttrs">
                    <v-textarea label="Thank you message HTML" v-model="form.thankYouMessageHtml.value" :disabled="!form.thankYouMessageHtml.included" rows="1"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-bind="leftColumnAttrs">
                    <v-checkbox v-model="form.templateDescription.included"></v-checkbox>
                </v-col>
                <v-col v-bind="rightColumnAttrs">
                    <v-textarea label="Template description" v-model="form.templateDescription.value" :disabled="!form.templateDescription.included" rows="1"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-bind="leftColumnAttrs">
                    <v-checkbox v-model="form.emailSubjectTemplate.included"></v-checkbox>
                </v-col>
                <v-col v-bind="rightColumnAttrs">
                    <v-textarea label="Email subject template" v-model="form.emailSubjectTemplate.value" :disabled="!form.emailSubjectTemplate.included" rows="1"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-bind="leftColumnAttrs">
                    <v-checkbox v-model="form.showGreenBox.included"></v-checkbox>
                </v-col>
                <v-col v-bind="rightColumnAttrs">
                    <v-checkbox label="Show Green Box" v-model="form.showGreenBox.value" :disabled="!form.showGreenBox.included"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-bind="leftColumnAttrs">
                    <v-checkbox v-model="form.tags.included"></v-checkbox>
                </v-col>
                <v-col v-bind="rightColumnAttrs">
                    <v-textarea label="Tags" v-model="form.tags.value" :disabled="!form.tags.included" rows="1"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-bind="leftColumnAttrs">
                    <v-checkbox v-model="form.templateFile.included"></v-checkbox>
                </v-col>
                <v-col v-bind="rightColumnAttrs">
                    <v-text-field label="Template file" v-model="form.templateFile.value" :disabled="!form.templateFile.included"/>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ContextTitle from "components/context_title";
import {mapActions, mapGetters} from "vuex";
import TemplateCardWrapper from "components/receipts/TemplateCardWrapper";

export default {
    name: "bulk_edit",
    components: {
        ContextTitle,
        TemplateCardWrapper,
    },
    data() {
        return {
            form: {
                isEnabled: {
                    included: false,
                    value: false,
                },
                headerLogoHtml: {
                    included: false,
                    value: null,
                },
                thankYouMessageHtml: {
                    included: false,
                    value: null,
                },
                templateDescription: {
                    included: false,
                    value: null,
                },
                emailSubjectTemplate: {
                    included: false,
                    value: null,
                },
                showGreenBox: {
                    included: false,
                    value: false,
                },
                tags: {
                    included: false,
                    value: null,
                },
                templateFile: {
                    included: false,
                    value: null,
                }
            },
            leftColumnAttrs: {
                sm: 2,
            },
            rightColumnAttrs: {
                sm: 10,
            },
        };
    },
    mounted() {
        this.fetchReceiptTemplates(this.waypointId);
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.previousRoute = from
        })
    },
    computed: {
        ...mapGetters("ReceiptsStore", ["receiptsStatus", "receiptTemplates"]),
        waypointId() {
            return Number.parseInt(this.$route.params.waypointID, 10);
        },
        templateIds() {
            return Array.isArray(this.$route.query.templateIds)
                ? this.$route.query.templateIds
                : [this.$route.query.templateIds];
        },
        title() {
            return `Bulk edit ${this.templateIds.length} receipt templates`;
        },
        titleBarActions() {
            const cancel = {
                type: "cancel",
                display: "Cancel",
                run: this.cancel,
            };

            const save = {
                type: "proceed",
                display: "Save",
                run: this.save,
            };

            return [cancel, save];
        },
        filteredTemplates() {
            return this.receiptTemplates
                .filter(x => _.includes(this.templateIds, x.templateID))
        },
    },
    methods:{
        ...mapActions("ReceiptsStore", ["fetchReceiptTemplates"]),
        cancel() {
            this.navigateBackToReceipts();
        },
        save() {
            console.error(this.form);
        },
        navigateBackToReceipts() {
            this.$router.push(this.previousRoute && this.previousRoute.name === 'receipts'
                ? this.previousRoute
                : {name: 'receipts'}
            );
        },
    }
}
</script>

<style scoped>

</style>
